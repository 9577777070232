const config = {
  errorMessage: {
    404: 'The id seems incorrect',
    500: 'Something went wrong',
    default: 'Something went wrong',
  },
  segments: {
    city: {
      icon: 'location',
    },
    country: {
      icon: 'location',
    },
    day: {
      icon: 'calendar',
    },
    dayOfWeek: {
      icon: 'calendar',
      label: 'Day of the Week',
      options: {
        0: 'Sunday',
        1: 'Monday',
        2: 'Tuesday',
        3: 'Wednesday',
        4: 'Thursday',
        5: 'Friday',
        6: 'Saturday',
      },
    },
    default: {
      icon: null,
    },
    deviceOs: {
      icon: 'deviceOs',
      label: 'Device OS',
      options: {
        android: 'Android',
        ios: 'iOS',
        windows: 'Windows',
        mac: 'Mac',
      },
    },
    deviceType: {
      icon: 'deviceType',
      label: 'Device Type',
      options: {
        mobile: 'Mobile',
        tablet: 'Tablet',
        desktop: 'Desktop',
      },
    },
    hour: {
      icon: 'time',
    },
    poi: {
      icon: 'location',
      label: 'Points of Interest',
    },
    weather: {
      icon: 'weather',
    },
    zipcode: {
      icon: 'location',
    },
    podcast: {
      icon: 'podcast',
    },
    random: {
      icon: 'random',
    },
  },
};

export default config;
