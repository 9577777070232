export function fetchHeaders(headers = {}) {
  const ContentType = headers.ContentType || 'application/json';

  return {
    headers: new Headers({
      'Content-Type': ContentType,
      'Access-Control-Allow-Origin': true,
    }),
    credentials: 'include',
  };
}

export function fetchRequest(url, options = {}) {
  const { headers, ...opts } = options;
  return new Request(url, { ...fetchHeaders(headers), ...opts });
}

export function isNullOrUndefined(value) {
  return value === null || value === undefined;
}

export function getCookie(key) {
  if (!document.cookie.length) return false;

  const target = document.cookie
    .split('; ')
    .find((item) => item.startsWith(`${key}=`));

  return target ? target.split('=')[1] : false;
}

export function formatDuration(duration) {
  const roundDuration = Math.round(duration);
  const minutes = roundDuration / 60;
  const seconds = roundDuration % 60;

  return [minutes, seconds]
    .map((item) => `0${String(Math.floor(item))}`.slice(-2))
    .join(':');
}

export function parseHTML(string = '') {
  return new DOMParser().parseFromString(string, 'text/html').body.textContent;
}
