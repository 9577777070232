/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Segment from '../Segment';

import { container } from './index.scss';

export default class Segments extends PureComponent {
  render() {
    const {
      segmentOrder,
      segments,
      onPlaySegment,
      onStopSegment,
      onSetSegment,
      isPlayingIndex,
      hasEndedPlaying,
    } = this.props;

    return (
      <div className={container}>
        {segmentOrder.map((current, index) => (
          <Segment
            key={`${current}_${index}`}
            name={current}
            array={segments[index]}
            onPlaySegment={onPlaySegment}
            onStopSegment={onStopSegment}
            onSetSegment={onSetSegment}
            position={index}
            isPlaying={isPlayingIndex === index}
            hasEnded={hasEndedPlaying === index}
          />
        ))}
      </div>
    );
  }
}

Segments.propTypes = {
  segmentOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
  segments: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)).isRequired,
  isPlayingIndex: PropTypes.number,
  hasEndedPlaying: PropTypes.number,
  onPlaySegment: PropTypes.func.isRequired,
  onStopSegment: PropTypes.func.isRequired,
  onSetSegment: PropTypes.func.isRequired,
};

Segments.defaultProps = {
  isPlayingIndex: null,
  hasEndedPlaying: null,
};
