/* global __ADSERVER__ */
import { hot } from 'react-hot-loader/root';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { CssBaseline, Modal, Typography } from '@material-ui/core';
import autobind from 'autobind-decorator';
import Loader from '../components/Loader';
import Footer from '../components/Footer';
import Player from '../components/Player';
import Segments from '../components/Segments';
import { isNullOrUndefined } from '../utils';

import Audion from '../images/Audion-white.svg';

import {
  main,
  logo,
  logoIsPlaying,
  modalBody,
  background,
} from './CreativesScreen.scss';

import config from '../config';

const creativeAPI = 'public/creatives/dco';

const baseUrl = __ADSERVER__;

export class CreativesScreen extends Component {
  constructor() {
    super();
    this.state = {
      url: null,
      isReady: false,
      segment: null,
      segmentTS: null,
      isPlayingIndex: null,
      hasEndedPlaying: null,
      isBuiltSegmentPlaying: false,
      modalOpen: false,
      modalMessage: null,
    };
    this.creative = null;
    this.selectedSegments = [];
    this.segmentsUri = '';
    this.builtSegmentsUri = '';
    this.segmentPlayer = React.createRef();
    this.player = { current: null };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { segmentTS } = this.state;
    const { segmentTS: prevSegmentTS } = prevState;

    if (segmentTS !== prevSegmentTS) {
      this.segmentPlayer.current.play();
      this.segmentPlayer.current.addEventListener('ended', this.onEndedSegment);
    }
  }

  @autobind
  onPlayerReady(player) {
    this.player.current = player;
  }

  @autobind
  async onSetSegment(segment, index) {
    this.selectedSegments[index] = segment;

    const url = await this.getUrl();
    this.setState({
      url,
      isBuiltSegmentPlaying: false,
    });
  }

  @autobind
  onPlayBuiltSegment() {
    this.onStopSegment();
    this.setState({ isBuiltSegmentPlaying: true });
  }

  @autobind
  onEndedBuiltSegment() {
    this.setState({ isBuiltSegmentPlaying: false });
  }

  @autobind
  onPauseBuiltSegment() {
    this.setState({ isBuiltSegmentPlaying: false });
  }

  @autobind
  onPlaySegment(name, index) {
    if (this.player.current) {
      this.player.current.pause();
    }
    this.segmentPlayer.current.pause();
    this.segmentPlayer.current.currentTime = 0;
    this.setState({
      segment: this.segmentsUri + name,
      segmentTS: Date.now(),
      isPlayingIndex: index,
      isBuiltSegmentPlaying: false,
      hasEndedPlaying: null,
    });
  }

  @autobind
  onStopSegment() {
    this.segmentPlayer.current.pause();
    this.segmentPlayer.current.currentTime = 0;
    this.segmentPlayer.current.removeEventListener(
      'ended',
      this.onEndedSegment
    );
    this.setState({
      isPlayingIndex: null,
      hasEndedPlaying: null,
    });
  }

  @autobind
  onEndedSegment() {
    const { isPlayingIndex } = this.state;
    this.segmentPlayer.current.currentTime = 0;
    this.segmentPlayer.current.removeEventListener(
      'ended',
      this.onEndedSegment
    );
    this.setState({
      isPlayingIndex: null,
      hasEndedPlaying: isPlayingIndex,
    });
  }

  getUrl() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const { errorMessage } = config;

    const selectedBuiltSegment = this.selectedSegments
      .map((segment) => {
        if (segment.type === 'podcast' && segment.display) {
          return segment.display
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/[^a-zA-Z0-9]+/g, '-')
            .toUpperCase();
        }
        return segment.value.label || segment.value;
      })
      .join('_')
      .toUpperCase();

    return fetch(
      `${baseUrl}/${creativeAPI}/${id}/segmentsCombinations?name=${selectedBuiltSegment}`
    )
      .then((res) => {
        if (!res.ok) {
          throw res.status;
        }
        return res.json();
      })
      .then((res) => {
        const { audios } = res;
        if (!audios) throw new Error();

        const mp3Audio = audios.find((audio) => audio.extension === 'mp3');
        if (!mp3Audio) throw new Error();

        return mp3Audio.publicUrl;
      })
      .catch(() => {
        this.setState({
          modalOpen: true,
          modalMessage: errorMessage.default,
        });
      });
  }

  async prepareData(creative) {
    this.creative = creative;
    this.selectedSegments = creative.segments.map((segment) => segment[0]);
    this.segmentsUri = creative.assets.uri.audios;
    this.builtSegmentsUri = creative.assets.uri.builtSegments;
    this.totalBuiltSegments = creative.segments.reduce((acc, current) => {
      return acc * current.length;
    }, 1);

    const url = await this.getUrl();
    this.setState({
      url,
      isReady: true,
    });
  }

  fetchData() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const { errorMessage } = config;

    fetch(`${baseUrl}/${creativeAPI}/${id}`)
      .then((res) => {
        if (!res.ok) {
          throw res.status;
        }
        return res.json();
      })
      .then((res) => {
        const [creative] = res;
        if (creative) {
          this.prepareData(creative);
        } else {
          this.setState({
            modalOpen: true,
            modalMessage: errorMessage.default,
          });
        }
      })
      .catch(() => {
        this.setState({
          modalOpen: true,
          modalMessage: errorMessage.default,
        });
      });
  }

  render() {
    const {
      url,
      isReady,
      segment,
      isPlayingIndex,
      hasEndedPlaying,
      isBuiltSegmentPlaying,
      modalOpen,
      modalMessage,
    } = this.state;

    let segmentOrder;
    let segments;

    if (this.creative) {
      ({ segmentOrder, segments } = this.creative);
    }

    return (
      <div className={main}>
        <CssBaseline />
        <div className={background} />
        <div
          className={
            !isNullOrUndefined(isPlayingIndex) || isBuiltSegmentPlaying
              ? [logo, logoIsPlaying].join(' ')
              : logo
          }
        >
          <Audion />
        </div>
        {isReady ? (
          <Fragment>
            <Segments
              segmentOrder={segmentOrder}
              segments={segments}
              onPlaySegment={this.onPlaySegment}
              onStopSegment={this.onStopSegment}
              onSetSegment={this.onSetSegment}
              isPlayingIndex={isPlayingIndex}
              hasEndedPlaying={hasEndedPlaying}
            />
            <Player
              poster={this.creative.companionBanner.banners[0].url}
              name={this.creative.name}
              start={this.creative.start}
              end={this.creative.end}
              url={url}
              total={this.totalBuiltSegments}
              onPlayerReady={this.onPlayerReady}
              onPlay={this.onPlayBuiltSegment}
              onPause={this.onPauseBuiltSegment}
              onEnded={this.onEndedBuiltSegment}
              propsRef={this.player}
              isPlaying={isBuiltSegmentPlaying}
            />
            <Footer />
            <audio src={segment} ref={this.segmentPlayer} />
          </Fragment>
        ) : (
          <Loader />
        )}
        <Modal open={modalOpen}>
          <div className={modalBody}>
            <Typography variant='subtitle1'>{modalMessage}</Typography>
          </div>
        </Modal>
      </div>
    );
  }
}

CreativesScreen.propTypes = {
  match: PropTypes.shape().isRequired,
};

export default hot(CreativesScreen);
