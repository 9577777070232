import { hot } from 'react-hot-loader/root';
import React, { PureComponent } from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import { StylesProvider } from '@material-ui/styles';
import CreativesScreen from './main/CreativesScreen';
import FourOhFour from './main/FourOhFour';

class App extends PureComponent {
  render() {
    return (
      <StylesProvider injectFirst>
        <BrowserRouter>
          <Switch>
            <Route exact path='/creatives/:id' component={CreativesScreen} />
            <Route component={FourOhFour} />
          </Switch>
        </BrowserRouter>
      </StylesProvider>
    );
  }
}

export default hot(App);
