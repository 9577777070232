import React from 'react';
import { Grid, Card, Typography } from '@material-ui/core';

import Audion from '../images/Audion.svg';

import { container, card, logo } from './FourOhFour.scss';

export const FourOhFour = () => (
  <Grid container className={container}>
    <Grid item xs={3} />
    <Grid item xs={6}>
      <Card className={card}>
        <div className={logo}>
          <Audion />
        </div>
        <Typography variant='h1'>404</Typography>
      </Card>
    </Grid>
  </Grid>
);

export default FourOhFour;
