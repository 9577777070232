import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import WaveSurfer from 'wavesurfer.js';

import { Paper } from '@material-ui/core';

import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import { formatDuration } from '../../utils';

import {
  player,
  playerIsPlaying,
  time,
  controls,
  controlsTop,
  play,
  playIsPlaying,
  playBtn,
  title,
  date,
  waveform,
  timeline,
  infos,
  count,
} from './index.scss';

export default class Player extends PureComponent {
  constructor(props) {
    super(props);

    this.player = React.createRef();
    this.currentTime = React.createRef();
    this.duration = React.createRef();
    this.wavesurfer = null;
  }

  componentDidMount() {
    const { onPlayerReady, url } = this.props;

    this.wavesurfer = WaveSurfer.create({
      container: this.player.current,
      waveColor: '#f7b0b6',
      progressColor: '#f0626d',
      height: 48,
      cursorWidth: 0,
      barWidth: 2,
    });

    this.wavesurfer.load(url);

    this.wavesurfer.on('ready', () => {
      onPlayerReady(this.wavesurfer);
      this.currentTime.current.innerHTML = formatDuration(0);
      this.duration.current.innerHTML = formatDuration(
        this.wavesurfer.getDuration()
      );
    });

    this.wavesurfer.on('play', this.onPlay);
    this.wavesurfer.on('pause', this.onPause);
    this.wavesurfer.on('finish', this.onEnded);
  }

  componentDidUpdate(prevProps) {
    const { url: prevUrl } = prevProps;
    const { url } = this.props;

    if (prevUrl !== url) {
      this.wavesurfer.load(url);
    }
  }

  componentWillUnmount() {
    this.wavesurfer.un('play', this.onPlay);
    this.wavesurfer.un('pause', this.onPause);
    this.wavesurfer.un('finish', this.onEnded);
  }

  @autobind
  onPlay() {
    const { onPlay } = this.props;

    this.watchProgress();
    onPlay();
  }

  @autobind
  onPause() {
    const { onPause } = this.props;

    onPause();
  }

  @autobind
  onEnded() {
    const { onEnded } = this.props;

    onEnded();
  }

  @autobind
  onChange() {
    if (this.wavesurfer.isPlaying()) {
      this.wavesurfer.pause();
    }
  }

  @autobind
  watchProgress() {
    this.currentTime.current.innerHTML = formatDuration(
      this.wavesurfer.getCurrentTime()
    );
    if (this.wavesurfer.isPlaying()) {
      requestAnimationFrame(this.watchProgress);
    }
  }

  @autobind
  togglePlay() {
    if (this.wavesurfer.isPlaying()) {
      this.wavesurfer.pause();
    } else {
      this.wavesurfer.play();
    }
  }

  render() {
    const { name, start, end, poster, isPlaying, total } = this.props;

    return (
      <Paper
        className={isPlaying ? [player, playerIsPlaying].join(' ') : player}
      >
        <img src={poster} alt='' />
        <div className={controls}>
          <div className={timeline}>
            <div className={time} ref={this.currentTime} />
            <div className={waveform} ref={this.player} />
            <div className={time} ref={this.duration} />
          </div>
          <div className={controlsTop}>
            <div className={isPlaying ? [play, playIsPlaying].join(' ') : play}>
              <button
                type='button'
                className={playBtn}
                onClick={this.togglePlay}
              >
                {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
              </button>
            </div>
            <div className={infos}>
              <p className={title}>{name}</p>
              <p className={date}>
                {`From ${new Date(start).toLocaleDateString()} to ${new Date(
                  end
                ).toLocaleDateString()}`}
              </p>
            </div>
          </div>
          <span className={count}>{total}</span>
        </div>
      </Paper>
    );
  }
}

Player.propTypes = {
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  poster: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  onPlayerReady: PropTypes.func.isRequired,
  onPlay: PropTypes.func.isRequired,
  onPause: PropTypes.func.isRequired,
  onEnded: PropTypes.func.isRequired,
  isPlaying: PropTypes.bool.isRequired,
};
