import React, { PureComponent } from 'react';
import { Link } from '@material-ui/core';
import { footer, copyright, link } from './index.scss';

export default class Footer extends PureComponent {
  render() {
    const url = 'https://audi-on.com/';
    const urlLabel = url;
    const copyrightLabel = '© Copyright 2021 Audion - Made with ♥ in Paris';

    return (
      <div className={footer}>
        <span className={copyright}>{copyrightLabel}</span>
        <Link className={link} href={url} target='_blank'>
          {urlLabel}
        </Link>
      </div>
    );
  }
}
