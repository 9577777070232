import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Typography } from '@material-ui/core';
import { loader, loaderLabel } from './index.scss';

export default class Loader extends PureComponent {
  render() {
    const { label } = this.props;

    return (
      <div className={loader}>
        <CircularProgress />
        {label && (
          <Typography variant='caption' className={loaderLabel}>
            {label}
          </Typography>
        )}
      </div>
    );
  }
}

Loader.propTypes = {
  label: PropTypes.string,
};

Loader.defaultProps = {
  label: null,
};
