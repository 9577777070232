import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import Select, { components } from 'react-select';

import { withStyles } from '@material-ui/core/styles';
import { Typography, Switch, FormControlLabel } from '@material-ui/core';

import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';

import config from '../../config';

import WeatherIcon from '../../images/weather.svg';
import DeviceTypeIcon from '../../images/deviceType.svg';
import LocationIcon from '../../images/location.svg';
import CalendarIcon from '../../images/calendar.svg';
import DeviceOsIcon from '../../images/deviceOs.svg';
import TimeIcon from '../../images/time.svg';
import ArrowIcon from '../../images/arrow.svg';
import MicIcon from '../../images/mic.svg';
import RandomIcon from '../../images/dice.svg';

import {
  segment,
  segmentSingle,
  segmentFace,
  segmentFaceIsPlaying,
  icon,
  count,
  select,
  segmentName,
  play,
  playIsPlaying,
  playBtn,
  playAllSwitch,
  playAllSwitchActive,
  playAllSwitchLabel,
  updated,
} from './index.scss';

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 30,
    height: 16,
    padding: 0,
    margin: theme.spacing(1),
    marginLeft: theme.spacing(1) / 2,
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      color: theme.palette.common.white,
      transform: 'translateX(87.5%)',
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 14,
    height: 14,
    borderWidth: 0,
    boxShadow: 'none',
  },
  track: {
    borderRadius: 16 / 2,
    borderWidth: 0,
    backgroundColor: '#e0e5ea',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const animationTiming = 1500;

const getLabel = (current, segments) => {
  const { type, value, display } = current;
  if (type === 'random') {
    return `Random ${value}`;
  }
  return (
    (segments[type] &&
      segments[type].options &&
      segments[type].options[value]) ||
    display ||
    value
  );
};

export default class Segment extends Component {
  static handleOpen() {
    // Open on selected option TMP FIX
    // https://github.com/JedWatson/react-select/pull/3652
    setTimeout(() => {
      const selected = document.querySelector('.prefix__option--is-selected');
      if (selected) {
        selected.scrollIntoView();
      }
    }, 1);
  }

  constructor(props) {
    super(props);

    this.state = {
      playAll: false,
      selectedIndex: 0,
    };

    this.segment = React.createRef();

    this.selectedIndex = 0;
    this.icons = {
      weather: WeatherIcon,
      deviceType: DeviceTypeIcon,
      location: LocationIcon,
      calendar: CalendarIcon,
      deviceOs: DeviceOsIcon,
      time: TimeIcon,
      arrow: ArrowIcon,
      podcast: MicIcon,
      random: RandomIcon,
    };
  }

  componentDidUpdate(prevProps) {
    const { hasEnded, array, position, onPlaySegment } = this.props;
    const { hasEnded: prevHasEnded } = prevProps;
    const { playAll, selectedIndex } = this.state;

    const nextIndex = selectedIndex + 1;

    if (!prevHasEnded && hasEnded && playAll) {
      if (array[nextIndex]) {
        this.setSegment(nextIndex);
        onPlaySegment(array[nextIndex].name, position);
        this.doChangeEffect();
      } else {
        this.resetSwitch();
      }
    }
  }

  @autobind
  onToggleAudio() {
    const {
      array,
      isPlaying,
      onPlaySegment,
      onStopSegment,
      position,
    } = this.props;
    const { selectedIndex } = this.state;

    if (isPlaying) {
      onStopSegment();
    } else {
      onPlaySegment(array[selectedIndex].name, position);
    }
  }

  setSegment(index) {
    const { array, onSetSegment, position } = this.props;

    onSetSegment(array[index], position);
    this.setState({ selectedIndex: index });
  }

  resetSwitch() {
    const { array, onSetSegment, position } = this.props;
    const index = 0;

    onSetSegment(array[index], position);
    this.setState({ playAll: false, selectedIndex: index });
  }

  @autobind
  handleChange({ value }) {
    const { onStopSegment } = this.props;

    onStopSegment();
    this.setSegment(value);
  }

  doChangeEffect() {
    this.segment.current.classList.add(updated);

    setTimeout(this.undoChangeEffect.bind(this), animationTiming);
  }

  undoChangeEffect() {
    this.segment.current.classList.remove(updated);
  }

  generateOptions() {
    const { array } = this.props;
    const { segments } = config;

    return array.map((current, index) => ({
      value: index,
      label: getLabel(current, segments),
    }));
  }

  @autobind
  switchPlayAll(event) {
    this.setState({ playAll: event.target.checked });
  }

  render() {
    const { name, array, isPlaying } = this.props;
    const { playAll, selectedIndex } = this.state;
    const { segments } = config;

    const options = this.generateOptions();

    const Icon = this.icons[segments[name].icon];

    const isSingle = array.length === 1;

    const customStyles = {
      container: (styles) => ({
        ...styles,
        fontSize: 12,
        fontFamily: '"Montserrat", sans-serif',
        fontWeight: 500,
      }),
      control: (styles, state) => ({
        ...styles,
        height: '2.75rem',
        borderColor:
          state.isFocused || state.menuIsOpen ? '#f0626d' : 'transparent',
        boxShadow: null,
        position: 'relative',
        '&:hover': {
          borderColor: '#f0626d',
          '.prefix__single-value': {
            color: '#f0626d',
          },
        },
        '.prefix__single-value': {
          color: state.isFocused ? '#f0626d' : 'hsl(0, 0%, 20%)',
          visibility: state.menuIsOpen ? 'hidden' : 'visible',
        },
        '.prefix__value-container:before': {
          display:
            state.menuIsOpen && !state.selectProps.inputValue.length
              ? 'block'
              : 'none',
          content: '"Search…"',
          top: '50%',
          transform: 'translateY(-50%)',
          position: 'absolute',
          marginLeft: 2,
          marginRight: 2,
          color: '#ababab',
        },
      }),
      dropdownIndicator: (styles) => ({
        ...styles,
        color: '#151049',
        padding: 16,
        ':hover': { color: '#151049' },
      }),
      indicatorSeparator: (styles) => ({
        ...styles,
        width: 0,
      }),
      menu: (styles) => ({
        ...styles,
        overflow: 'hidden',
        zIndex: 20,
      }),
      menuList: (styles) => ({
        ...styles,
        paddingBottom: 8,
        paddingTop: 8,
      }),
      option: (styles, state) => ({
        ...styles,
        padding: '12px 16px',
        color: state.isSelected ? 'white' : '#151049',
      }),
      singleValue: (styles) => ({
        ...styles,
        color: '#151049',
      }),
      valueContainer: (styles) => ({
        ...styles,
        paddingLeft: 14,
      }),
    };

    const switchLabel = (
      <Typography className={playAllSwitchLabel}>Autoplay</Typography>
    );

    return (
      <div
        className={isSingle ? [segment, segmentSingle].join(' ') : segment}
        ref={this.segment}
      >
        <div
          className={
            isPlaying
              ? [segmentFace, segmentFaceIsPlaying].join(' ')
              : segmentFace
          }
        >
          {Icon && (
            <div className={icon}>
              <Icon />
            </div>
          )}
          <div className={isPlaying ? [play, playIsPlaying].join(' ') : play}>
            <button
              type='button'
              className={playBtn}
              onClick={this.onToggleAudio}
            >
              {isPlaying ? <StopIcon /> : <PlayArrowIcon />}
            </button>
          </div>
          <span className={segmentName}>
            {(segments[name] && segments[name].label) || name}
          </span>
          <span className={count}>{options.length}</span>
        </div>
        <Select
          className={select}
          isSearchable
          value={options[selectedIndex]}
          options={options}
          onChange={this.handleChange}
          onMenuOpen={Segment.handleOpen}
          styles={customStyles}
          classNamePrefix='prefix'
          components={{
            DropdownIndicator: (props) => (
              <components.DropdownIndicator {...props}>
                <ArrowIcon />
              </components.DropdownIndicator>
            ),
          }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: '#f0626d',
              primary25: '#fbd7da',
              primary50: '#f7b0b6',
              primary75: '#f48d95',
            },
            borderRadius: '0.5rem',
          })}
        />
        <FormControlLabel
          className={
            playAll
              ? [playAllSwitch, playAllSwitchActive].join(' ')
              : playAllSwitch
          }
          control={
            <IOSSwitch checked={playAll} onChange={this.switchPlayAll} />
          }
          label={switchLabel}
        />
      </div>
    );
  }
}

Segment.propTypes = {
  name: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  array: PropTypes.arrayOf(PropTypes.object).isRequired,
  onPlaySegment: PropTypes.func.isRequired,
  onStopSegment: PropTypes.func.isRequired,
  onSetSegment: PropTypes.func.isRequired,
  isPlaying: PropTypes.bool.isRequired,
  hasEnded: PropTypes.bool.isRequired,
};
